import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from 'styled-components';
import { Color, ScalingSize } from '../config/variables';
import { WrapMax, WrapXs } from '../components/shared/wraps';
import { Page, Button, Heading1, Excerpt } from '../components/shared';
import Fade from 'react-reveal/Fade';

const NotFoundPage = () => {
  return (
    <Page>
      <SEO title="UX-designbyrå i Sundsvall, Stockholm och världen." />
      <WrapMax data-color={Color.white}>
        <Fade bottom distance={'10%'} fraction={0.2}>
          <Heading1 primary center>
            Sidan finns inte
          </Heading1>
        </Fade>
      </WrapMax>
      <WrapCenter>
        <Fade bottom distance={'10%'} fraction={0.2}>
          <Excerpt data-color={Color.white}>Sidan du sökte efter finns inte.</Excerpt>
        </Fade>
        <Fade bottom distance={'10%'} fraction={0.2}>
          <Button inline bg={Color.citrus5} cover direction="left" to="/" text="Till startsidan" icon="ArrowRight" />
        </Fade>
      </WrapCenter>
    </Page>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const WrapCenter = styled(WrapXs)`
  text-align: center;
  margin-bottom: 6em;
`;
